import React from 'react';
import { HeroBgWrapper, HeroWrapper } from './HeroStyles';
import { AwsmText } from '../../atoms/typography/Typography';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmButton } from '../../atoms/buttons/AwsmButton';
import { LeftArrow, RightArrow } from '../../assets/icons/Arrows';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';

const Hero = () => {
  const { isMobile } = useResponsiveWidth();
  return (
    <HeroWrapper id='hero'>
      <HeroBgWrapper>
        <FlexCol>
          <FlexCol
            height='auto'
            mWidth='800px'
            justifyContent='center'
            gap='1rem'
          >
            <AwsmText
              centered
              size={isMobile ? '2.2rem' : '4rem'}
              weight={800}
              color='#FFF9E2'
            >
              Find peace in Ayodhya’s sacred spaces.
            </AwsmText>
            <AwsmText centered size={'1.2rem'} weight={500} color='#FFF9E2'>
              Let us guide you through Ayodhya’s rich spiritual heritage.
              Explore, experience, and find serenity with our unique tour
              packages.
            </AwsmText>
          </FlexCol>
          <FlexRow
            width={isMobile ? 'auto' : '500px'}
            height='auto'
            // mTop={isMobile ? '1rem' : '2rem'}
            justifyContent={!isMobile ?? 'space-between'}
            gap='1rem'
          >
            <AwsmButton
              gradient
              width={isMobile ? '300px' : '48%'}
              height={isMobile ?? 'auto'}
              onClick={() => {
                const element = document.getElementById('booking');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Book your trip now
              <RightArrow stroke='#fbf6df' />
            </AwsmButton>
            <AwsmButton
              width={isMobile ? '300px' : '48%'}
              height={isMobile ?? 'auto'}
              primary
              onClick={() => {
                const element = document.getElementById('discover');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Explore Ayodhya
              <RightArrow stroke='#8f242b' />
            </AwsmButton>
          </FlexRow>
        </FlexCol>
      </HeroBgWrapper>
    </HeroWrapper>
  );
};

export default Hero;
