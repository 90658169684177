import React from 'react';
import { PlansWrapper } from './PlanStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { PiFlowerLotusFill } from 'react-icons/pi';
import { AwsmText } from '../../atoms/typography/Typography';
import PlanCard from './PlanCard';
import OneDayPlan from '../../assets/images/OneDay.png';
import TwoDayPlan from '../../assets/images/TwoDay.png';
import ThreeDayPlan from '../../assets/images/ThreeDay.png';
import Heading from '../../atoms/typography/Heading';

const tourPackages = [
  {
    name: '1 - Day Tour',
    days: 1,
    planImage: OneDayPlan,
    benefits: [
      'Sri Ram Mandir Darshan (Ram Janm Bhumi) Darshan & Aarti',
      'Hanuman Gari Darshan',
      'Dashrath Mehel',
      'Kanak Bhavan',
      'Ram ki Paidi',
      'Nageshwar Nath Temple (Jal Arpan)',
      'Saryu Maiya Ghat',
      'Lata Mangeshkar Chauk',
      'Suraj Kund',
    ],
    price: 10000,
    discountedPrice: 8000,
    guestsAllowed: 2,
    type: 'base',
  },
  {
    name: '2 - Day Tour',
    days: 2,
    planImage: TwoDayPlan,
    benefits: [
      'Guptar Ghat',
      'Panchmukhi Mahadev Temple',
      'Old Guptarghat Temple',
      'Company Garden Ayodhya',
      'Bharat Kund',
    ],
    price: 17500,
    discountedPrice: 15500,
    guestsAllowed: 2,
    type: 'sub-plan',
  },
  {
    name: '3 - Day Tour',
    days: 3,
    planImage: ThreeDayPlan,
    benefits: [
      'Sringi Rishi Ashram',
      'Makhauda Dham',
      'Dashrath Samadhi Sthal',
    ],
    price: 26500,
    discountedPrice: 22500,
    guestsAllowed: 2,
    type: 'sub-plan',
  },
];

const Plans = ({ id }) => {
  return (
    <PlansWrapper id={id}>
      <FlexCol gap={'1.5rem'}>
        <Heading
          color={'#ab353d'}
          text={'Select from a curated list of tour packages'}
          subtext='With thoughtful itineraries and a deep connection to the city’s
          spiritual heart, we ensure your visit isn’t just a tour — it’s a
          personal & meaningful experience that stays with you.'
        />
        {/* <FlexRow gap={'1rem'}>
          <PiFlowerLotusFill
            style={{ marginTop: '6px' }}
            color='#ab353d'
            size={'1.5rem'}
          />
          <AwsmText weight={'600'} gradient={false} size={'2rem'}>
            Select from a curated list of tour packages
          </AwsmText>
          <PiFlowerLotusFill
            style={{ marginTop: '6px' }}
            color='#ab353d'
            size={'1.5rem'}
          />
        </FlexRow>
        <AwsmText weight={400} size={'1.2rem'} gradient={false}>
          With thoughtful itineraries and a deep connection to the city’s
          spiritual heart, we ensure your visit isn’t just a tour — it’s a
          personal & meaningful experience that stays with you.
        </AwsmText> */}
        <FlexRow mTop={'4rem'} gap={'1.5rem'}>
          {tourPackages.map(
            (
              {
                name,
                planImage,
                benefits,
                price,
                guestsAllowed,
                type,
                days,
                discountedPrice,
              },
              idx
            ) => {
              return (
                <PlanCard
                  key={idx}
                  planName={name}
                  planImg={planImage}
                  price={price}
                  discountedPrice={discountedPrice}
                  guests={guestsAllowed}
                  benefits={benefits}
                  days={days}
                  planType={type}
                />
              );
            }
          )}
        </FlexRow>
      </FlexCol>
    </PlansWrapper>
  );
};

export default Plans;
