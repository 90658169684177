import styled from 'styled-components';

const FormContainer = styled.div`
  border-radius: 6px;
  border: 2px solid #ab353d;
  background: #fbf6df;
  padding: 1.5rem 4rem;
  position: absolute;
  right: 5rem;
  position: absolute;
  left: -5rem;
  min-width: 350px;

  @media screen and (max-width: 480px) {
    width: auto;
    min-width: 280px;
    position: static;
    padding: 10px 15px;
  }
`;

const InputWrapper = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  position: ${({ relative }) => relative && 'relative'};
`;

const FormField = styled.input`
  padding: 7px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ab353d;
  color: #9f676b;

  &::placeholder {
    color: rgba(171, 53, 61, 0.6);
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 4px 8px;
    font-size: 10px;
  }
`;

const RadioContainer = styled.div`
  width: 100%;
  max-height: 125px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`;

const RadioWrapper = styled.div`
  flex: 1;
  max-width: 30%;
  aspect-ratio: 1;
  border-radius: 10px;
  box-shadow: 0px 3px 4px 0px rgba(171, 53, 61, 0.5);
`;

const RadioImage = styled.div`
  height: 60%;
  background: ${({ radioImg }) => `url(${radioImg})`};
  background-size: cover;
  background-position: ${({ centered }) => centered && 'center'};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const RadioInput = styled.div`
  height: 40%;
  border: 0.5 solid #ab353d;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  background: ${({ clicked }) => clicked && '#AB353D'};

  @media screen and (max-width: 480px) {
    gap: 8px;
  }
`;

const AwsmRadioBtn = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: ${({ clicked }) =>
    clicked ? '5px solid #E5D480' : '2px solid #C7878B'};
  background: #fbf6df;

  @media screen and (max-width: 480px) {
    width: 5.5px;
    height: 5.5px;
    border: ${({ clicked }) =>
      clicked ? '4px solid #E5D480' : '2px solid #C7878B'};
  }
`;

const AwsmDropdown = styled.select`
  display: flex;
  padding: 7px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ab353d;
  color: #9f676b;

  &::placeholder {
    color: rgba(171, 53, 61, 0.6);
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 4px 8px;
    font-size: 10px;
  }
`;

const AwsmDropdownOption = styled.option`
  border-radius: 6px;
  border: 1px solid #ab353d;
  color: rgba(171, 53, 61, 0.6);
`;

export {
  FormContainer,
  FormField,
  InputWrapper,
  RadioContainer,
  RadioWrapper,
  RadioImage,
  RadioInput,
  AwsmRadioBtn,
  AwsmDropdown,
  AwsmDropdownOption,
};
