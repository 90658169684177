import styled from 'styled-components';

const AwsmText = styled.p`
  padding: 0;
  margin: 0;
  font-size: ${({ size }) => (size ? size : 'calc(2.5 / 100 * 95vw)')};
  font-weight: ${({ weight }) => (weight ? weight : '800')};
  font-family: ${({ secondary }) => (secondary ? 'Karla' : 'Playfair Display')};
  background: ${({ gradient }) =>
    gradient ? 'linear-gradient(0deg, #dc444f 0%, #76242a 100%)' : 'none'};
  background-clip: ${({ gradient }) => gradient && 'text'};
  -webkit-background-clip: ${({ gradient }) => gradient && 'text'};
  -webkit-text-fill-color: ${({ gradient }) => gradient && 'transparent'};
  color: ${({ color }) => (color ? color : '#ab353d')};
  /* z-index: 150; */
  vertical-align: middle;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

const AwsmHref = styled.a`
  padding: 0;
  margin: 0;
  font-size: ${({ size }) => (size ? size : 'calc(2.5 / 100 * 95vw)')};
  font-weight: ${({ weight }) => (weight ? weight : '800')};
  font-family: ${({ secondary }) => (secondary ? 'Karla' : 'Playfair Display')};
  background: ${({ gradient }) =>
    gradient ? 'linear-gradient(0deg, #dc444f 0%, #76242a 100%)' : 'none'};
  background-clip: ${({ gradient }) => gradient && 'text'};
  -webkit-background-clip: ${({ gradient }) => gradient && 'text'};
  -webkit-text-fill-color: ${({ gradient }) => gradient && 'transparent'};
  color: ${({ color }) => (color ? color : '#ab353d')};
  /* z-index: 150; */
  vertical-align: middle;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  text-decoration: none;
`;

export { AwsmText, AwsmHref };
