import styled from 'styled-components';

const WhyUsWrapper = styled.section`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(10 / 100 * 100vw) calc(5 / 100 * 100vw);
  background: #8f242b;
  background-size: cover;

  @media screen and (max-width: 480px) {
    padding: 4rem 2rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  gap: 1rem;
  margin-top: 40px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const ServiceCard = styled.div`
  flex: 1 1 33%;
  min-height: 150px;
  border-radius: 20px;
  border: 2px solid #fbf6df;
  background: ${({ bgURL }) => `linear-gradient(
      0deg,
      rgba(29, 6, 6, 0.7) 0%,
      rgba(29, 6, 6, 0.7) 100%
    ),
    url(${bgURL}) lightgray 0px -86.389px / 100% 155.324% no-repeat`};
  /* background-size: cover; */
  /* background-position: center; */
  text-align: center;
  padding: 1rem;

  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
`;

const CardLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  font-size: 5rem;
  color: #fbf6df;
`;

const CardHeader = styled.p`
  padding: 0;
  margin: 0;
  color: #fbf6df;
  font-family: 'Karla', sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const CardSubtext = styled.p`
  padding: 0;
  margin: 0;
  color: #fbf6df;
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  overflow: hidden;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export {
  WhyUsWrapper,
  CardsContainer,
  ServiceCard,
  CardLogo,
  CardHeader,
  CardSubtext,
};
