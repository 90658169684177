import styled from 'styled-components';
import TestimonialBg from '../../assets/images/TestimonialBg.png';

const TestimonialSecion = styled.section`
  height: 100%;
  background: #fbf6df;
  padding: calc(10 / 100 * 100vw) calc(5 / 100 * 100vw);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TestimonialsHeaderWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: #ead991;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    border-radius: 12px;
  }
`;

const HeaderFirstHalf = styled.div`
  width: 25%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8f242b;
  border-radius: 20px;
  font-size: 2rem;
  gap: 1rem;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 10px;
    gap: 5px;
    border-radius: 12px;
  }
`;

const HeaderSecondHalf = styled.div`
  width: 75%;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 5px;
    gap: 5px;
    border-radius: 12px;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 280px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const AvatarWrap = styled.div`
  position: relative;
`;

const UserAvatar = styled.div`
  width: ${({ width }) => width ?? '25px'};
  height: ${({ height }) => height ?? '25px'};
  border-radius: 50%;
  color: #fff;
  background: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: ${({ absolute }) => absolute && 'absolute'};
  left: ${({ left }) => left};
  text-transform: uppercase;
`;

const TestimonialCardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const TestimonialCard = styled.div`
  min-height: 200px;
  border-radius: 20px;
  padding: 1.5rem;
  background: url(${TestimonialBg}) #ead991 no-repeat;
  background-position: bottom right;
`;

const ArrowBtnLeft = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ab353d;
  position: absolute;
  top: 45%;
  left: -20px;
  border: 2px solid #fbf6df;
  color: #fbf6df;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ArrowBtnRight = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ab353d;
  position: absolute;
  top: 45%;
  right: -20px;
  border: 2px solid #fbf6df;
  color: #fbf6df;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const WrapHeadline = styled.div`
  text-align: left;
  max-width: 200px;
  text-overflow: ellipsis;
`;

export {
  TestimonialSecion,
  TestimonialsHeaderWrap,
  HeaderFirstHalf,
  HeaderSecondHalf,
  UserAvatar,
  RatingWrapper,
  AvatarWrap,
  TestimonialCardWrapper,
  TestimonialCard,
  ArrowBtnLeft,
  ArrowBtnRight,
  WrapHeadline,
};
