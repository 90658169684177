import styled from 'styled-components';
import SubHeroBg from '../../assets/images/SubHeroBg.png';

const DiscoverWrapper = styled.section`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(10 / 100 * 100vw) calc(5 / 100 * 100vw);
  background: url(${SubHeroBg});
  background-size: cover;
`;

const GalleryCard = styled.div`
  width: calc(25 / 100 * 100vw);
  height: calc(15 / 100 * 100vw);
  border-radius: 6px;
  border: 6px solid rgba(143, 36, 43, 0.16);
  background: ${({ bgURL }) =>
    `url(${bgURL}) lightgray -0.425px -25.619px / 121.264% 118.299% no-repeat`};
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-clip: padding-box;

  @media screen and (max-width: 480px) {
    width: 280px;
    height: 150px;
  }
`;

export { DiscoverWrapper, GalleryCard };
