import styled from 'styled-components';

export const FlexCol = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  max-width: ${({ mWidth }) => mWidth};
  max-height: ${({ mHeight }) => mHeight};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-between'};
  gap: ${({ gap }) => gap ?? gap};
  margin-bottom: ${({ mBottom }) => mBottom};
  position: ${({ position }) => position};
`;
  
export const FlexRow = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  /* vertical-align: middle; */
  gap: ${({ gap }) => gap};
  margin-top: ${({ mTop }) => mTop};
  position: ${({ position }) => position};

  @media screen and (max-width: 480px) {
    /* flex-direction: column; */
  }
`;

export const FlexDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
`;
