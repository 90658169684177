import React from 'react';
import { FooterTopSection, TempleGraphic } from './FooterStyles';
import { FlexRow } from '../../atoms/containers/FlexContainers';
import TempleLeft from '../../assets/images/TempleLeft.png';
import TempleRight from '../../assets/images/TempleRight.png';

const FooterTop = () => {
  return (
    <FooterTopSection>
      <FlexRow justifyContent='space-between'>
        <TempleGraphic bgURL={TempleLeft} />
        <TempleGraphic bgURL={TempleRight} />
      </FlexRow>
    </FooterTopSection>
  );
};

export default FooterTop;
