import React from 'react';
import {
  CardHeader,
  CardLogo,
  CardsContainer,
  CardSubtext,
  ServiceCard,
  WhyUsWrapper,
} from './WhyUsStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import Heading from '../../atoms/typography/Heading';
import Service1 from '../../assets/images/Service1.jpeg';
import Service2 from '../../assets/images/Service2.jpeg';
import Service3 from '../../assets/images/Service3.jpeg';
import Service4 from '../../assets/images/Service4.jpeg';
import Service5 from '../../assets/images/Service5.jpeg';
import Service6 from '../../assets/images/Service6.jpeg';
import { MdEmojiTransportation } from 'react-icons/md';
import PricesIcon from '../../assets/icons/MoneyBag';
import CustomerServiceIcon from '../../assets/icons/CustomerService';
import { FaHotel } from 'react-icons/fa';
import { FaPerson } from 'react-icons/fa6';
import { IoLocation } from 'react-icons/io5';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';

const services = [
  {
    name: 'Transport',
    desc: 'Pickup & Drop From Airport, Railway Station, Bus Station',
    bgURL: Service1,
    icon: <MdEmojiTransportation />,
  },
  {
    name: 'Pleasant Spots',
    desc: 'Our package locations are soothing, relaxing, and eye-catching',
    bgURL: Service2,
    icon: <IoLocation />,
  },
  {
    name: 'Best Prices',
    desc: 'Our services offer more facilities and greater authenticity than others',
    bgURL: Service3,
    icon: PricesIcon,
  },
  {
    name: 'Luxury Hotels',
    desc: 'We offer luxurious, affordable hotels to recharge for the next day',
    bgURL: Service4,
    icon: <FaHotel />,
  },
  {
    name: 'Support 24x7',
    desc: 'We provide the best telephonic and email support 24/7',
    bgURL: Service5,
    icon: CustomerServiceIcon,
  },
  {
    name: 'Travel Guide',
    desc: 'We will provide you with a fully planned trip when you start with us',
    bgURL: Service6,
    icon: <FaPerson />,
  },
];

const WhyUs = () => {
  const { isMobile } = useResponsiveWidth();

  return (
    <WhyUsWrapper>
      <FlexCol gap='1.5rem'>
        <Heading
          color={'#FBF6DF'}
          text={'Why choose us'}
          subtext='We pride ourselves on our in-depth knowledge of Ayodhya’s history, culture, and spiritual significance. Our team of experts has crafted each tour package to ensure you get a comprehensive and authentic Ayodhya experience.'
        />
        <CardsContainer>
          {services.map((service) => (
            <ServiceCard bgURL={service.bgURL}>
              <FlexRow
                justifyContent={isMobile ? 'center' : 'flex-start'}
                alignItems='center'
                gap={'1rem'}
              >
                <CardLogo>{service.icon}</CardLogo>
                <FlexCol
                  width='70%'
                  gap='10px'
                  justifyContent='center'
                  alignItems='flex-start'
                >
                  <CardHeader>{service.name}</CardHeader>
                  <CardSubtext>
                    Pickup & Drop From Airport, Railway Station, Bus Station.
                  </CardSubtext>
                </FlexCol>
              </FlexRow>
            </ServiceCard>
          ))}
        </CardsContainer>
      </FlexCol>
    </WhyUsWrapper>
  );
};

export default WhyUs;
