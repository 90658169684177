export default (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='95'
    height='95'
    viewBox='0 0 126 114'
    fill='none'
  >
    <path
      d='M105 74.2486C107.187 68.9361 108.375 63.2486 108.375 56.9986C108.375 52.4986 107.687 48.1861 106.5 44.1861C102.437 45.1236 98.1875 45.6236 93.75 45.6236C84.6623 45.6334 75.7055 43.4581 67.6346 39.2813C59.5636 35.1044 52.6152 29.0483 47.375 21.6236C41.7747 35.1908 31.1982 46.1027 17.8125 52.1236C17.5625 53.6861 17.5625 55.3736 17.5625 56.9986C17.5625 62.9656 18.7378 68.8741 21.0212 74.3868C23.3047 79.8995 26.6516 84.9085 30.8708 89.1278C39.392 97.649 50.9492 102.436 63 102.436C69.5625 102.436 75.875 100.999 81.5625 98.4361C85.125 105.249 86.75 108.624 86.625 108.624C76.375 112.061 68.4375 113.749 63 113.749C47.875 113.749 33.4375 107.811 22.8125 97.1236C16.3441 90.6857 11.5392 82.7705 8.8125 74.0611H0.5V45.6236H7.3125C9.37651 35.5768 14.1218 26.2762 21.0449 18.7086C27.968 11.141 36.8107 5.58867 46.6346 2.64087C56.4584 -0.306936 66.8971 -0.540298 76.8429 1.96554C86.7887 4.47139 95.8706 9.62296 103.125 16.8736C110.999 24.721 116.371 34.7254 118.562 45.6236H125.5V74.0611H125.125L102.875 94.4986L69.75 90.7486V80.3111H99.9375L105 74.2486ZM45.9375 55.5611C47.8125 55.5611 49.625 56.3111 50.9375 57.6861C52.2565 59.0159 52.9967 60.8131 52.9967 62.6861C52.9967 64.5592 52.2565 66.3563 50.9375 67.6861C49.625 68.9986 47.8125 69.7486 45.9375 69.7486C42 69.7486 38.8125 66.6236 38.8125 62.6861C38.8125 58.7486 42 55.5611 45.9375 55.5611ZM80 55.5611C83.9375 55.5611 87.0625 58.7486 87.0625 62.6861C87.0625 66.6236 83.9375 69.7486 80 69.7486C76.0625 69.7486 72.875 66.6236 72.875 62.6861C72.875 60.7965 73.6257 58.9842 74.9619 57.648C76.2981 56.3118 78.1103 55.5611 80 55.5611Z'
      fill='#FBF6DF'
    />
  </svg>
);
