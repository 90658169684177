import React from 'react';
import {
  BookingFirstHalf,
  BookingSecondHalf,
  BookingWrapper,
} from './BookingStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmText } from '../../atoms/typography/Typography';
import AwsmForm from '../Form/AwsmForm';

const Booking = ({ id }) => {
  return (
    <BookingWrapper id={id}>
      <FlexRow justifyContent='flex-start'>
        <BookingFirstHalf>
          <FlexCol
            alignItems='flex-start'
            mWidth='500px'
            justifyContent='center'
            gap='1.5rem'
          >
            <AwsmText size='2.5rem' weight={600}>
              Ready to visit Ayodhya?
            </AwsmText>
            <AwsmText weight={400} size={'1.2rem'}>
              With thoughtful itineraries and a deep connection to the city’s
              spiritual heart, we ensure your visit isn’t just a tour — it’s a
              personal & meaningful experience that stays with you.
            </AwsmText>
          </FlexCol>
        </BookingFirstHalf>
        <BookingSecondHalf>
          <AwsmForm />
        </BookingSecondHalf>
      </FlexRow>
    </BookingWrapper>
  );
};

export default Booking;
