import styled from 'styled-components';
import { CiCalendar } from 'react-icons/ci';
import React, { useState } from 'react';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material';

export const AwsmIconInput = styled.input.attrs({ type: 'text' })`
  display: flex;
  padding: 7px 30px 7px 14px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ab353d;
  color: #9f676b;

  &::placeholder {
    color: rgba(171, 53, 61, 0.6);
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
    padding: 4px 12px 4px 8px;
  }
`;

const InputIcon = styled(CiCalendar)`
  position: absolute;
  right: 10px;
  top: 70%;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
`;

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderColor: '#ab353d' },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: '#FFF9E2',
            borderWidth: '1px',
            border: '1px solid #ab353d',
            backgroundColor: '#ab353d',
          },
        },
      },
    },
  });

const AwsmCalendarInput = ({ name, value, onChange }) => {
  return (
    <ThemeProvider theme={newTheme}>
      <DesktopDatePicker
        name={name}
        value={value}
        onChange={onChange}
        slotProps={{
          day: (params) => ({ sx: { color: '#FFF9E2' } }),
          leftArrowIcon: (params) => ({ sx: { color: '#FFF9E2' } }),
          rightArrowIcon: (params) => ({ sx: { color: '#FFF9E2' } }),
          switchViewIcon: (params) => ({ sx: { color: '#FFF9E2' } }),
          calendarHeader: (params) => ({ sx: { background: '#ab353d' } }),
          textField: (params) => ({ sx: { borderColor: '#ab353d' } }),
          actionBar: (params) => ({
            sx: { background: '#ab353d', color: '#FFF9E2' },
          }),
          button: (params) => ({ sx: { color: '#FFF9E2' } }),
        }}
      />
    </ThemeProvider>
  );
};

export default AwsmCalendarInput;
