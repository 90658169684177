export const LeftArrow = ({ stroke }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='13'
    viewBox='0 0 30 13'
    fill='none'
  >
    <path
      d='M28.5 7.15247H0.500001M0.500001 7.15247C2.15278 6.8608 5.45833 5.57747 5.45833 2.77747M0.500001 7.15247C2.15278 7.44413 5.45833 8.72747 5.45833 11.5275'
      stroke={`${stroke}`}
      stroke-width='1.45833'
      stroke-linecap='round'
    />
  </svg>
);

export const RightArrow = ({ stroke }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='30'
    height='13'
    viewBox='0 0 30 13'
    fill='none'
  >
    <path
      d='M1.5 7.15247H29.5M29.5 7.15247C27.8472 6.8608 24.5417 5.57747 24.5417 2.77747M29.5 7.15247C27.8472 7.44413 24.5417 8.72747 24.5417 11.5275'
      stroke={`${stroke}`}
      stroke-width='1.45833'
      stroke-linecap='round'
    />
  </svg>
);
