import styled from 'styled-components';
import BookingBg from '../../assets/images/BookingBg.png';

const BookingWrapper = styled.section`
  height: 120vh;

  @media screen and (max-width: 480px) {
    height: 100%;
  }
`;

const BookingFirstHalf = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #e8d789 0%, #ffeee2 100%);

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 10rem 4rem;
  }
`;

const BookingSecondHalf = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BookingBg});
  background-size: cover;
  background-position: center;
  position: relative;

  @media screen and (max-width: 480px) {
    width: 100%;
    position: static;
    padding: 4rem 2rem;
  }
`;

export { BookingWrapper, BookingFirstHalf, BookingSecondHalf };
