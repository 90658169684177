import React, { useState } from 'react';
import {
  BurgerMenu,
  MobileNav,
  MobNavMenu,
  NavContainer,
  NavItems,
  NavLogo,
  NavText,
  NavWrapper,
} from './NavBarStyles';
import { FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmText } from '../../atoms/typography/Typography';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';
import { FaBars } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const { isMobile } = useResponsiveWidth();
  const [mobMenuActive, setMobMenuActive] = useState(false);

  return (
    <>
      <NavContainer>
        <NavWrapper>
          <FlexRow gap='1rem' width='70%' justifyContent='flex-start'>
            <NavLogo
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            />
            <AwsmText
            style={{cursor: 'pointer'}}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              // style={{ display: `${isMobile && 'none'}` }}
              size={isMobile ? '0.8rem' : '1.5rem'}
              weight={600}
              color='#FFF9E2'
            >
              {isMobile ? (
                <>
                  AYODHYA <br /> DARSHANAM
                </>
              ) : (
                'AYODHYA DARSHANAM'
              )}
            </AwsmText>
          </FlexRow>
          <BurgerMenu onClick={() => setMobMenuActive((active) => !active)}>
            {mobMenuActive ? (
              <FaTimes color='#FFF9E2' />
            ) : (
              <FaBars color='#FFF9E2' />
            )}
          </BurgerMenu>
          <NavItems>
            <NavText to='#pricing' smooth={true} duration={500}>
              Pricing
            </NavText>
            <NavText to='#discover' smooth={true} duration={500}>
              Explore
            </NavText>
            <NavText to='#contact-us' smooth={true} duration={500}>
              Contact Us
            </NavText>
          </NavItems>
        </NavWrapper>
      </NavContainer>
      <MobileNav active={mobMenuActive}>
        <MobNavMenu onClick={() => setMobMenuActive(false)}>
          <NavText to='#pricing' smooth={true} duration={500}>
            Pricing
          </NavText>
          <NavText to='#discover' smooth={true} duration={500}>
            Explore
          </NavText>
          <NavText to='#contact-us' smooth={true} duration={500}>
            Contact Us
          </NavText>
        </MobNavMenu>
      </MobileNav>
    </>
  );
};

export default Navbar;
