import React, { useState } from 'react';
import {
  AwsmDropdown,
  AwsmDropdownOption,
  AwsmRadioBtn,
  FormContainer,
  FormField,
  InputWrapper,
  RadioContainer,
  RadioImage,
  RadioInput,
  RadioWrapper,
} from './AwsmFormStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmText } from '../../atoms/typography/Typography';
import Service4 from '../../assets/images/Service4.jpeg';
import Service1 from '../../assets/images/Service1.jpeg';
import Ghat from '../../assets/images/Ghat.jpeg';
import AwsmCalendarInput from '../../atoms/inputs/AwsmCalendarInput';
import { AwsmButton } from '../../atoms/buttons/AwsmButton';
import { LeftArrow, RightArrow } from '../../assets/icons/Arrows';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';
import dayjs from 'dayjs';

const packageOptions = [
  {
    name: '1-Day Tour',
    image: Service4,
    value: 'one-day',
  },
  {
    name: '2-Day Tour',
    image: Service1,
    value: 'two-day',
  },
  {
    name: '3-Day Tour',
    image: Ghat,
    value: 'three-day',
  },
];

const pickUpOptions = ['Railway Station', 'Bus Station', 'Airport'];

const LabelledInput = ({
  isMobile,
  label,
  name,
  placeholder,
  width,
  value,
  onChange,
  type,
}) => {
  return (
    <>
      <InputWrapper width={width}>
        <AwsmText size={isMobile ? '0.5rem' : '0.8rem'} weight={400}>
          {label}
        </AwsmText>
        <FormField
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          placeholder={placeholder}
        />
      </InputWrapper>
    </>
  );
};

const AwsmForm = ({ formHeader, formSubHeader, formStage }) => {
  const { isMobile } = useResponsiveWidth();
  const [selectedPackage, setSelectedPackage] = useState('one-day');
  const [formData, setFormData] = useState({
    guestName: '',
    guestEmail: '',
    guestPhone: '',
    date: dayjs(new Date().toLocaleDateString()),
    pickupLocation: 'Railway Station',
    guestCount: 0,
  });

  const handleChange = (e) => {
    setFormData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const sendDataToSheets = async () => {
    const response = await fetch(
      'https://script.google.com/macros/s/AKfycbyNsxsrnEWU6iWoYKUJJze7OwUa5fgpsOeMuuP_YQIp7iulTpFG-tuGl7xcTRjxXWn58Q/exec',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=utf-8',
        },
        body: JSON.stringify({ ...formData, package: selectedPackage }),
        redirect: 'follow',
      }
    );

    if (response.ok) {
      alert(
        "Your trip with Ayodhya Darshanam is booked successfully! We'll call you shortly..."
      );
    } else {
      alert('Failed to book the trip.');
    }
  };

  const onSubmit = () => {
    console.log(formData);
    sendDataToSheets();
  };

  const resetForm = () => {
    setFormData({
      guestName: '',
      guestEmail: '',
      guestPhone: '',
      date: dayjs(new Date().toLocaleDateString()),
      pickupLocation: 'Railway Station',
      guestCount: 0,
    });
  };

  return (
    <>
      <FormContainer id={'form'}>
        <FlexCol alignItems='flex-start'>
          <FlexCol mBottom='1rem' gap='0.5rem'>
            <AwsmText size={isMobile ? '1.2rem' : '2rem'} weight={500}>
              Book your trip now!
            </AwsmText>
            <AwsmText size={isMobile ? '10px' : '0.8rem'} weight={200}>
              Experience Ayodhya: A Journey Through Time and Faith
            </AwsmText>
          </FlexCol>

          <LabelledInput
            name={'guestName'}
            type={'text'}
            value={formData['guestName']}
            label={'Name'}
            onChange={handleChange}
            placeholder={'Enter your name here...'}
            isMobile={isMobile}
          />
          <FlexRow justifyContent='space-between'>
            <LabelledInput
              name='guestEmail'
              label={'Email'}
              type='email'
              value={formData['guestEmail']}
              onChange={handleChange}
              placeholder={'Enter your email here...'}
              width={'48%'}
              isMobile={isMobile}
            />
            <LabelledInput
              name='guestPhone'
              label={'Contact Number'}
              placeholder={'Enter your mobile number here...'}
              width={'48%'}
              type='tel'
              value={formData['guestPhone']}
              onChange={handleChange}
              isMobile={isMobile}
            />
          </FlexRow>
          <FlexRow>
            <AwsmText weight='400' size={isMobile ? '0.8rem' : '1rem'}>
              Select your package
            </AwsmText>
          </FlexRow>
          <RadioContainer>
            {packageOptions.map((_package, idx) => {
              return (
                <RadioWrapper key={idx}>
                  <RadioImage centered={idx === 1} radioImg={_package.image} />
                  <RadioInput
                    clicked={selectedPackage === _package.value}
                    onClick={() => setSelectedPackage(_package.value)}
                  >
                    <AwsmRadioBtn
                      clicked={selectedPackage === _package.value}
                    />
                    <AwsmText
                      secondary
                      size={isMobile ? '0.7rem' : '1rem'}
                      weight={500}
                      color={selectedPackage === _package.value && '#FBF6DF'}
                    >
                      {_package.name}
                    </AwsmText>
                  </RadioInput>
                </RadioWrapper>
              );
            })}
          </RadioContainer>
          <FlexRow mTop='1rem' justifyContent='space-between'>
            <LabelledInput
              name='date'
              label={'Date'}
              placeholder={'Enter your travel date here...'}
              width={'48%'}
              type='date'
              value={formData['date']}
              onChange={handleChange}
              isMobile={isMobile}
            />
            <LabelledInput
              name='guestCount'
              label={'Number Of Guests'}
              placeholder={'Enter the number of guests...'}
              width={'48%'}
              type='number'
              value={formData['guestCount']}
              onChange={handleChange}
              isMobile={isMobile}
            />
          </FlexRow>
          <InputWrapper>
            <AwsmText size={isMobile ? '0.5rem' : '0.8rem'} weight={400}>
              Pick Up Location
            </AwsmText>
            <AwsmDropdown
              onChange={handleChange}
              value={formData['pickupLocation']}
              name='pickupLocation'
              placeholder='Select your pick up location...'
            >
              {pickUpOptions.map((opt, idx) => (
                <AwsmDropdownOption value={opt} key={idx}>
                  {opt}
                </AwsmDropdownOption>
              ))}
            </AwsmDropdown>
          </InputWrapper>
          <FlexRow
            mTop={isMobile ? '1rem' : '2rem'}
            justifyContent='space-between'
          >
            <AwsmButton onClick={resetForm} primary width='48%'>
              <LeftArrow stroke='#8f242b' />
              Reset
            </AwsmButton>
            <AwsmButton onClick={onSubmit} gradient width='48%'>
              Submit
              <RightArrow stroke='#fbf6df' />
            </AwsmButton>
          </FlexRow>
        </FlexCol>
      </FormContainer>
    </>
  );
};

export default AwsmForm;
