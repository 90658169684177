import React from 'react';
import { FlexRow } from '../containers/FlexContainers';
import { PiFlowerLotusFill } from 'react-icons/pi';
import { AwsmText } from './Typography';

const Heading = ({ text, subtext, color }) => {
  return (
    <>
      <FlexRow gap={'1rem'}>
        <PiFlowerLotusFill
          style={{ marginTop: '6px' }}
          color={color}
          size={'1.5rem'}
        />
        <AwsmText
          centered
          color={color}
          weight={'600'}
          gradient={false}
          size={'2rem'}
        >
          {text}
        </AwsmText>
        <PiFlowerLotusFill
          style={{ marginTop: '6px' }}
          color={color}
          size={'1.5rem'}
        />
      </FlexRow>
      <AwsmText
        centered
        color={color}
        weight={400}
        size={'1.2rem'}
        gradient={false}
      >
        {subtext}
      </AwsmText>
    </>
  );
};

export default Heading;
