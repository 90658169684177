import React from 'react';
import { DiscountedPrice, OriginalPrice, PricingText } from './PlanStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';

const Pricing = ({ original, discounted, heading, subtext }) => {
  return (
    <>
      <PricingText>{heading}</PricingText>
      <FlexRow gap='1rem'>
        <OriginalPrice size='2rem'>{`₹${original}`}</OriginalPrice>
        <DiscountedPrice size='2rem'>{`₹${discounted}`}</DiscountedPrice>
      </FlexRow>
      <PricingText>{subtext}</PricingText>
    </>
  );
};

export default Pricing;
