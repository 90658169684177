import styled from 'styled-components';

export const AwsmButton = styled.button`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  padding: 10px 15px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1.2rem')};
  color: ${({ primary }) => (primary ? '#AB464D' : '#FBF9F1')};
  border: ${({ primary }) =>
    primary ? '0.5px solid #AB464D' : '1px solid rgba(250, 243, 214, 0.70)'};
  background: ${({ primary, gradient }) =>
    primary
      ? 'linear-gradient(0deg, #FEF4C3 0%, #E5D480 100%)'
      : gradient
      ? 'linear-gradient(0deg, #DB4242 0%, #8C2B2B 100%)'
      : '#8F242B'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  gap: 1rem;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  }
`;
