import styled from 'styled-components';

const PlansWrapper = styled.section`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(10 / 100 * 100vw) calc(5 / 100 * 100vw);
  background: #fbf6df;
  background-size: cover;
`;

const PlanCardWrapper = styled.div`
  width: 350px;
  /* height: 1000px; */
  background: #8f242b;
  border-radius: 10px;
  padding: 20px;
`;

const PlanImage = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background: ${({ bgURL }) => `
    url(${bgURL})  no-repeat`};
  background-size: cover;
  display: flex;
  background-position: center;
`;

const StyledSeparator = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
`;

const PlanText = styled.p`
  padding: 0;
  margin: 0;
  color: rgba(250, 243, 214, 0.8);
  font-family: 'Karla';
  font-size: ${({ size }) => (size ? size : '1rem')};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  text-align: center;
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  position: relative;
`;

const CircleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #f5e3c3; /* Matching the circle color */
  border-radius: 50%;
  font-weight: bold;
  font-family: 'Playfair Display';
  color: #7f1f1f;
  font-size: 1.2rem;
  z-index: 1;
`;

const Line = styled.div`
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  height: calc(100% + 30px);
  width: 2px;
  background-color: #f5e3c3;
  z-index: 0;
`;

const ListText = styled.div`
  margin-left: 20px;
  font-size: 1rem;
  color: #faf3d6cc;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const PricingText = styled.p`
  padding: 0;
  margin: 0;
  color: #fae999;
  text-align: center;
  font-family: 'Karla';
  font-size: ${({ size }) => (size ? size : '1.2rem')};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : '400')};
  line-height: normal;
  letter-spacing: -0.8px;
`;

const OriginalPrice = styled.span`
  font-size: ${({ size }) => (size ? size : '1rem')};
  color: #d7a571; /* Matching the gold-ish color */
  position: relative;
  display: inline-block;
  font-family: 'Playfair Display';

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 140%; /* Adjust based on your price text width */
    height: 2px;
    background-color: #d7a571;
    top: 50%;
    transform: rotate(-25deg);
    left: -20%;
  }

  &:after {
    transform: rotate(25deg);
    top: 60%;
  }
`;

const DiscountedPrice = styled.span`
  color: #fae999;
  text-align: right;
  font-family: 'Playfair Display';
  font-size: ${({ size }) => (size ? size : '2rem')};
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : '400')};
  line-height: normal;
`;

export {
  PlanCardWrapper,
  PlanImage,
  PlansWrapper,
  StyledSeparator,
  Line,
  Circle,
  ListText,
  ListItem,
  ListWrapper,
  CircleWrapper,
  PlanText,
  PricingText,
  OriginalPrice,
  DiscountedPrice,
};
