import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArrowBtnLeft,
  ArrowBtnRight,
  HeaderFirstHalf,
  HeaderSecondHalf,
  RatingWrapper,
  TestimonialCardWrapper,
  TestimonialSecion,
  TestimonialsHeaderWrap,
  UserAvatar,
} from './TestimonialStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { PiFlowerLotusFill } from 'react-icons/pi';
import { AwsmText } from '../../atoms/typography/Typography';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import { IoMdStar } from 'react-icons/io';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';
import Card from './Card';

const avatarPositions = [20, 35, 50, 65];

const reviews = [
  {
    id: 1,
    stars: 5,
    headline: 'Amazing Experience!',
    review:
      'The tour was well-organized, and the guide was extremely knowledgeable. We got to visit all the key places in Ayodhya without any hassle. Highly recommended!',
    user: 'Saurabh Singh',
    date: '2024-09-21',
  },
  {
    id: 2,
    stars: 4,
    headline: 'Great Pilgrimage Tour',
    review:
      'The arrangements were very smooth, from travel to accommodation. The team made sure we were comfortable throughout the trip. A spiritual and fulfilling experience.',
    user: 'Priya Mehra',
    date: '2024-09-25',
  },
  {
    id: 3,
    stars: 5,
    headline: 'Excellent Service!',
    review:
      'Had a fantastic time! The travel agency took care of all the details, and the itinerary was perfect. The staff was courteous and helpful. Would book again.',
    user: 'Ravi Patel',
    date: '2024-09-28',
  },
  {
    id: 4,
    stars: 5,
    headline: 'Well-Organized Tour',
    review:
      'The entire trip was seamless, from the moment we were picked up to when we returned. The agency exceeded expectations in terms of service and planning.',
    user: 'Neha Sharma',
    date: '2024-10-01',
  },
  {
    id: 5,
    stars: 4,
    headline: 'Wonderful Ayodhya Visit',
    review:
      'The guides were knowledgeable, and the transport was comfortable. We were able to explore all the important landmarks. Overall, a delightful experience.',
    user: 'Arjun Rao',
    date: '2024-10-03',
  },
];

const Testimonials = ({ rating = 5, topUsers = [] }) => {
  const { isMobile } = useResponsiveWidth();
  const numberOfSlidesToShow = useMemo(() => (isMobile ? 1 : 3), [isMobile]);
  const [currIndices, setCurrIndices] = useState({
    start: 0,
    end: numberOfSlidesToShow,
  });

  const showNextSlide = () => {
    if (currIndices.end + 1 <= reviews.length) {
      setCurrIndices((curr) => {
        return {
          start: curr.start + 1,
          end: curr.end + 1,
        };
      });
    }
  };

  const showPrevSlide = () => {
    if (currIndices.start - 1 >= 0) {
      setCurrIndices((curr) => {
        return {
          start: curr.start - 1,
          end: curr.end - 1,
        };
      });
    }
  };

  const getRandomAlphabet = useCallback(function () {
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alphabets[Math.floor(Math.random() * alphabets.length)];
  }, []);

  const getRandomColor = useCallback(function () {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  }, []);

  return (
    <>
      <TestimonialSecion>
        <FlexCol gap='4rem'>
          <TestimonialsHeaderWrap>
            <HeaderFirstHalf>
              <PiFlowerLotusFill style={{ marginTop: '5px' }} color='#FBF6DF' />
              <AwsmText
                color='#FBF6DF'
                size={isMobile ? '0.8rem' : '2rem'}
                weight={800}
              >
                Testimonials
              </AwsmText>
            </HeaderFirstHalf>
            <HeaderSecondHalf>
              <RatingWrapper>
                <FlexCol alignItems='flex-end'>
                  <FlexRow justifyContent='flex-end'>
                    {Array.from({ length: rating }, (_, i) => i + 1).map(
                      (_, idx) => {
                        return (
                          <IoMdStar
                            key={idx}
                            size={isMobile ? '8px' : '1.1rem'}
                            color='#8F242B'
                          />
                        );
                      }
                    )}
                  </FlexRow>
                  <AwsmText size={isMobile ? '10px' : '0.8rem'} weight={500}>
                    rated on Google by
                  </AwsmText>
                </FlexCol>
                <FlexRow position='relative'>
                  {Array.from({ length: 4 }, (_, i) => i + 1).map((_, idx) => {
                    return (
                      <UserAvatar
                        absolute
                        left={`${avatarPositions[idx]}px`}
                        bgColor={getRandomColor()}
                      >
                        {getRandomAlphabet()}
                      </UserAvatar>
                    );
                  })}
                </FlexRow>
              </RatingWrapper>
            </HeaderSecondHalf>
          </TestimonialsHeaderWrap>
          <TestimonialCardWrapper>
            <ArrowBtnLeft onClick={showPrevSlide}>
              <GoArrowLeft size='1.5rem' />
            </ArrowBtnLeft>
            {reviews.length > 0 &&
              reviews
                .slice(currIndices.start, currIndices.end)
                .map((review, _) => {
                  return (
                    <Card
                      key={review.id}
                      reviewData={review}
                      randomAvatarColor={getRandomColor}
                    />
                  );
                })}
            <ArrowBtnRight onClick={showNextSlide}>
              <GoArrowRight size='1.5rem' />
            </ArrowBtnRight>
          </TestimonialCardWrapper>
        </FlexCol>
      </TestimonialSecion>
    </>
  );
};

export default Testimonials;
