import styled from 'styled-components';
import Darshanam from '../../assets/images/darshanam.png';
import { HashLink as Link } from 'react-router-hash-link';

const NavContainer = styled.nav`
  position: sticky;
  top: 0;
  z-index: 800;
`;

const NavWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background: #8f242b;
  padding: 1rem 1.5rem;
`;

const NavLogo = styled.div`
  width: 55px;
  height: 55px;
  background: url(${Darshanam});
  background-size: cover;
`;

const NavItems = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: '#fff9e2';
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const NavText = styled(Link)`
  color: #fff9e2;
  text-align: right;
  font-family: 'Karla', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
  padding: 0 0;
  margin: 0 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileNav = styled.aside`
  position: fixed;
  z-index: 900;
  width: 100%;
  background: #8f242b;
  display: grid;
  align-items: center;
  top: ${({ active }) => (active ? '75px' : 0)};
  left: ${({ active }) => (active ? 0 : '-100%')};
  transition: 0.3s ease-in-out;
  opacity: ${({ active }) => (active ? '100%' : 0)};
  padding: 1rem;
`;

const MobNavMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 45px);
  text-align: center;
`;

export {
  NavContainer,
  NavWrapper,
  NavLogo,
  NavItems,
  NavText,
  MobileNav,
  MobNavMenu,
  BurgerMenu,
};
