import React from 'react';
import Hero from '../components/Hero/Hero';
import Discover from '../components/Discover/Discover';
import Plans from '../components/Plans/Plans';
import WhyUs from '../components/WhyUs/WhyUs';
import Booking from '../components/Booking/Booking';
import Testimonials from '../components/testimonials/Testimonials';
import Footer from '../components/footer/Footer';
import { Element } from 'react-scroll';

const Home = () => {
  return (
    <>
      <Hero />
      <Discover id='discover' />
      <Plans id='pricing' />
      <WhyUs />
      <Booking id='booking' />
      <Testimonials />
      <Footer id='contact-us' />
    </>
  );
};

export default Home;
