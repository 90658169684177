import React from 'react';
import {
  Circle,
  CircleWrapper,
  Line,
  ListItem,
  ListText,
  ListWrapper,
  PlanCardWrapper,
  PlanImage,
  PlanText,
  StyledSeparator,
} from './PlanStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import Separator from '../../assets/images/Separator.png';
import { IoIosAdd } from 'react-icons/io';
import Pricing from './Pricing';
import { AwsmButton } from '../../atoms/buttons/AwsmButton';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';
import { useNavigate } from 'react-router-dom';

const PlanCard = ({
  planName,
  planImg,
  planType = 'base',
  benefits = [],
  price,
  discountedPrice,
  guests = 2,
  days,
}) => {
  const { isMobile } = useResponsiveWidth();

  return (
    <PlanCardWrapper>
      <FlexCol>
        <FlexCol height='80%' gap='1.5rem'>
          <PlanImage bgURL={planImg} />
          <StyledSeparator src={Separator} />
          {planType !== 'base' && (
            <>
              <PlanText size={'1.5rem'}>{`Everything included in ${
                days - 1
              } - Day Tour`}</PlanText>
              <IoIosAdd size='2rem' color='#FAF3D6CC' />
            </>
          )}
          <FlexCol alignItems={'flex-start'}>
            {benefits.map((benefit, idx) => (
              <ListWrapper key={idx + benefit}>
                <ListItem>
                  <CircleWrapper>
                    <Circle>{idx + 1}</Circle>
                    {idx < benefits.length - 1 && <Line />}
                  </CircleWrapper>
                  <ListText weight={600} size={'0.8rem'}>
                    {benefit}
                  </ListText>
                </ListItem>
              </ListWrapper>
            ))}
          </FlexCol>
        </FlexCol>
        <FlexCol gap={'1rem'} height='20%'>
          <Pricing
            original={price}
            discounted={discountedPrice}
            heading={'Just At'}
            subtext={'(for 2 guests)'}
          />
          <FlexRow mTop='1.2rem' gap={isMobile ? '0.8rem' : '1rem'}>
            <AwsmButton width={isMobile ? '120px' : '150px'}>
              Explore
            </AwsmButton>
            <AwsmButton
              onClick={() => {
                const element = document.getElementById('booking');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
              width={isMobile ? '120px' : '150px'}
              primary
            >
              Book Now!
            </AwsmButton>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </PlanCardWrapper>
  );
};

export default PlanCard;
