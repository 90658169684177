import React from 'react';
import FooterTop from './FooterTop';
import { FooterSection } from './FooterStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmHref, AwsmText } from '../../atoms/typography/Typography';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaEnvelope } from 'react-icons/fa';
import { NavLogo } from '../Navbar/NavBarStyles';
import { FaFacebookSquare } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaInstagram } from 'react-icons/fa6';
import { LeftArrow } from '../../assets/icons/Arrows';
import useResponsiveWidth from '../../hooks/useResponsiveWidth';

const Footer = ({ id }) => {
  const { isMobile } = useResponsiveWidth();

  return (
    <>
      <FooterTop />
      <FooterSection id={id}>
        <FlexRow gap={isMobile && '40px'} justifyContent='space-between'>
          {isMobile ? (
            <FlexCol mWidth='420px' width='auto' gap='15px'>
              <NavLogo />
              <AwsmText color='#fbf6df' size='1.2rem' weight={700}>
                AYODHYA DARSHANAM
              </AwsmText>
              <AwsmText
                centered
                secondary
                color='#fbf6df'
                size='1rem'
                weight={300}
              >
                Ayodhya Darshanam offers the best Ayodhya tour packages,
                understanding clients' needs, feelings, and devotion. It
                provides a wide range of customizable Ayodhya Darshan packages.
              </AwsmText>
              <FlexRow width='auto' gap='8px'>
                <FaFacebookSquare size='1.5rem' />
                <IoLogoYoutube size='1.5rem' />
                <FaInstagram size='1.5rem' />
              </FlexRow>
            </FlexCol>
          ) : (
            <FlexCol width='auto' alignItems='flex-start' gap={'15px'}>
              <AwsmText color='#fbf6df' size='1.2rem' weight={700}>
                CONTACT US
              </AwsmText>
              <FlexRow width='auto' gap='10px'>
                <BsFillTelephoneFill size='1rem' />
                <AwsmHref
                  href='tel:+917348205000'
                  secondary
                  color='#fbf6df'
                  size='1rem'
                  weight={300}
                >
                  7348205000
                </AwsmHref>
              </FlexRow>
              <FlexRow width='auto' gap='10px'>
                <FaEnvelope size='1rem' />
                <AwsmHref
                  href='mailto:bookings@ayodhyadarshanam.com'
                  secondary
                  color='#fbf6df'
                  size='1rem'
                  weight={300}
                >
                  bookings@ayodhyadarshanam.com
                </AwsmHref>
              </FlexRow>
            </FlexCol>
          )}
          {isMobile ? (
            <FlexCol width='auto' alignItems='flex-start' gap={'15px'}>
              <AwsmText color='#fbf6df' size='1.2rem' weight={700}>
                CONTACT US
              </AwsmText>
              <FlexRow width='auto' gap='10px'>
                <BsFillTelephoneFill size='1rem' />
                <AwsmHref
                  href='tel:+917348205000'
                  secondary
                  color='#fbf6df'
                  size='1rem'
                  weight={300}
                >
                  7348205000
                </AwsmHref>
              </FlexRow>
              <FlexRow width='auto' gap='10px'>
                <FaEnvelope size='1rem' />
                <AwsmHref
                  href='mailto:bookings@ayodhyadarshanam.com'
                  secondary
                  color='#fbf6df'
                  size='1rem'
                  weight={300}
                >
                  bookings@ayodhyadarshanam.com
                </AwsmHref>
              </FlexRow>
            </FlexCol>
          ) : (
            <FlexCol mWidth='420px' width='auto' gap='15px'>
              <NavLogo />
              <AwsmText color='#fbf6df' size='1.2rem' weight={700}>
                AYODHYA DARSHANAM
              </AwsmText>
              <AwsmText
                centered
                secondary
                color='#fbf6df'
                size='1rem'
                weight={300}
              >
                Ayodhya Darshanam offers the best Ayodhya tour packages,
                understanding clients' needs, feelings, and devotion. It
                provides a wide range of customizable Ayodhya Darshan packages.
              </AwsmText>
              <FlexRow width='auto' gap='8px'>
                <FaFacebookSquare size='1.5rem' />
                <IoLogoYoutube size='1.5rem' />
                <FaInstagram size='1.5rem' />
              </FlexRow>
            </FlexCol>
          )}

          <FlexCol
            alignItems={isMobile ? 'flex-start' : 'flex-end'}
            width='auto'
            gap='15px'
          >
            <AwsmText color='#fbf6df' size='1.2rem' weight={700}>
              SITEMAP
            </AwsmText>
            <FlexRow gap='10px' width='auto'>
              <AwsmText secondary size='1rem' weight='300' color='#fbf6df'>
                Home
              </AwsmText>
              <LeftArrow stroke='#fbf6df' />
            </FlexRow>
            <FlexRow gap='10px' width='auto'>
              <AwsmText secondary size='1rem' weight='300' color='#fbf6df'>
                About Us
              </AwsmText>
              <LeftArrow stroke='#fbf6df' />
            </FlexRow>
            <FlexRow gap='10px' width='auto'>
              <AwsmText secondary size='1rem' weight='300' color='#fbf6df'>
                Explore Ayodhya
              </AwsmText>
              <LeftArrow stroke='#fbf6df' />
            </FlexRow>
          </FlexCol>
        </FlexRow>
      </FooterSection>
    </>
  );
};

export default Footer;
