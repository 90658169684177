import React from 'react';
import { DiscoverWrapper, GalleryCard } from './DiscoverStyles';
import { FlexCol, FlexRow } from '../../atoms/containers/FlexContainers';
import { AwsmText } from '../../atoms/typography/Typography';
import { PiFlowerLotusFill } from 'react-icons/pi';
import NageshwarNath from '../../assets/images/Nageshwar-Nath.jpeg';
import LataMChowk from '../../assets/images/Lata-M-Chowk.jpeg';
import GuptarGhat from '../../assets/images/Ghat.jpeg';

const galleryImages = [
  {
    name: 'Nageshwar Nath',
    url: NageshwarNath,
  },
  {
    name: 'Lata Mangeshkar Chowk',
    url: LataMChowk,
  },
  {
    name: 'Guptar Ghat',
    url: GuptarGhat,
  },
];

const Discover = ({ id }) => {
  return (
    <DiscoverWrapper id={id}>
      <FlexCol gap={'1.5rem'}>
        <FlexRow gap={'1rem'}>
          <PiFlowerLotusFill
            style={{ marginTop: '6px' }}
            color='#ab353d'
            size={'1.5rem'}
          />
          <AwsmText centered weight={'600'} gradient={false} size={'2rem'}>
            Discover the soul of Ayodhya with us
          </AwsmText>
          <PiFlowerLotusFill
            style={{ marginTop: '6px' }}
            color='#ab353d'
            size={'1.5rem'}
          />
        </FlexRow>
        <AwsmText centered weight={400} size={'1.2rem'} gradient={false}>
          With thoughtful itineraries and a deep connection to the city’s
          spiritual heart, we ensure your visit isn’t just a tour — it’s a
          personal & meaningful experience that stays with you.
        </AwsmText>
        <FlexRow mTop={'4rem'} gap={'1.5rem'}>
          {galleryImages.map((img) => {
            return <GalleryCard key={img.name} bgURL={img.url} />;
          })}
        </FlexRow>
      </FlexCol>
    </DiscoverWrapper>
  );
};

export default Discover;
