import styled from 'styled-components';

const FooterTopSection = styled.div`
  height: 100%;
  background: #fbf6df;
`;

const TempleGraphic = styled.div`
  width: 220px;
  height: 220px;
  background: ${({ bgURL }) => `url(${bgURL})`} no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

const FooterSection = styled.section`
  height: 100%;
  padding: 40px;
  background: #8f242b;
  color: rgba(251, 246, 223, 0.8);
`;

export { FooterTopSection, TempleGraphic, FooterSection };
