import React from 'react';
import { TestimonialCard, UserAvatar, WrapHeadline } from './TestimonialStyles';
import {
  FlexCol,
  FlexDiv,
  FlexRow,
} from '../../atoms/containers/FlexContainers';
import { IoMdStar } from 'react-icons/io';
import { AwsmText } from '../../atoms/typography/Typography';

const Card = ({ reviewData, randomAvatarColor }) => {
  const { stars, headline, review, user, date } = reviewData;
  return (
    <TestimonialCard>
      <FlexCol gap='1.5rem'>
        <FlexCol alignItems='flex-start' height='15%' gap='5px'>
          <FlexRow justifyContent='flex-start'>
            {Array.from({ length: stars }, (_, i) => i + 1).map((_, idx) => {
              return <IoMdStar key={idx} size='1.1rem' color='#8F242B' />;
            })}
          </FlexRow>
          <WrapHeadline>
            <AwsmText size='1rem' weight={500}>{`"${headline}"`}</AwsmText>
          </WrapHeadline>
        </FlexCol>
        <FlexCol gap='2rem' justifyContent='flex-end' alignItems='flex-start'>
          <AwsmText secondary size='0.8rem' weight={400}>
            {review}
          </AwsmText>
          <FlexRow
            width='auto'
            height='auto'
            alignItems='flex-start'
            justifyContent='flex-start'
            gap='1rem'
          >
            <UserAvatar
              width='2.5rem'
              height='2.5rem'
              bgColor={randomAvatarColor()}
            >
              {user[0]}
            </UserAvatar>
            <FlexCol width='auto' height='auto' alignItems='flex-start'>
              <AwsmText size='1.2rem' weight={400} secondary>
                {user}
              </AwsmText>
              <AwsmText size='0.8rem' weight={400} secondary>
                {date}
              </AwsmText>
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexCol>
    </TestimonialCard>
  );
};

export default Card;
