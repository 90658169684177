import styled from 'styled-components';
import HeroBg from '../../assets/images/HeroBackground.jpeg';

const HeroWrapper = styled.section`
  height: 80vh;
  background: linear-gradient(
      0deg,
      rgba(143, 36, 43, 0.35) 0%,
      rgba(143, 36, 43, 0.35) 100%
    ),
    url(${HeroBg}) lightgray -99px 0px / 113.75% 106.83% no-repeat;
  background-position: center;
  background-size: cover;
  padding: 2rem;

  @media screen and (max-width: 480px) {
    padding: 3.5rem 2rem 5rem 2rem;
  }
`;

const HeroBgWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { HeroWrapper, HeroBgWrapper };
